const initDateEcheance = () => {
    const dateEmissionField = document.getElementById('date_emission');
    const dateEcheanceField = document.getElementById('date_echeance');

    const adjustDateEcheance = () => {
        const dateEmission = new Date(dateEmissionField.value);
        if (!isNaN(dateEmission.getTime())) {
            dateEmission.setDate(dateEmission.getDate() + 30);
            dateEcheanceField.value = dateEmission.toISOString().split('T')[0];
        }
    };

    const adjustDateEmission = () => {
        const dateEcheance = new Date(dateEcheanceField.value);
        if (!isNaN(dateEcheance.getTime())) {
            dateEcheance.setDate(dateEcheance.getDate() - 30);
            dateEmissionField.value = dateEcheance.toISOString().split('T')[0];
        }
    };

    if (dateEmissionField) {
        dateEmissionField.addEventListener('change', adjustDateEcheance);
    }

    if (dateEcheanceField) {
        dateEcheanceField.addEventListener('change', adjustDateEmission);
    }
};

export { initDateEcheance };
